import { DEFAULT_MODAL_DELAY_DAYS } from '../constants/config';

const DEFAULT_POPUP = 'default_popup_delay_temp';

export function markAsOpened() {
  if (DEFAULT_MODAL_DELAY_DAYS) {
    const now = new Date();
    const timestamp = new Date(now.getTime() + 60000 * 60 * 24 * DEFAULT_MODAL_DELAY_DAYS).getTime();
    localStorage.setItem(DEFAULT_POPUP, timestamp);
  }
}

export function isDelayed() {
  const item = localStorage.getItem(DEFAULT_POPUP);
  const now = new Date().getTime();

  return item && now < parseInt(item, 10);
}
