import { compose } from 'recompose';
import React, { Suspense, lazy } from 'react';
import { withThemeProvider } from 'travelopod-components';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import ModalMain from './modals';
import Content from './components/Content';
import * as routes from './constants/routes';
// const FetchUser = lazy(() => import('./components/FetchUser'));
const Banner = lazy(() => import('./components/Banner/Banner'));
const Footer = lazy(() => import('./components/Footer/Footer'));

import Loader from './components/Loader';
import Header from './components/Header/Header';
// const GoogleOneTapScript = lazy(() => import('./components/GoogleOneTapScript'));

const OzobiFooter = lazy(() => import('./components/OzobiPage/ozobiFooter'));
const OzobiHeader = lazy(() => import('./components/OzobiPage/ozobiHeader'));
const MarketingMessages = lazy(() => import('./components/MarketingMessages/MarketingMessages'));

const NotFound = lazy(() => import('./screens/NotFound'));
const FlightsPage = lazy(() => import('./screens/Flights/FlightsPage'));
const BookFlightPage = lazy(() => import('./screens/BookFlight/BookFlightPage'));
const SuccessfulConfirmation = lazy(() => import('./screens/SuccessfulPaymentConfirmation'));
const BookFlightSuccessPage = lazy(() => import('./screens/BookFlight/BookFlightSuccessPage'));
const BillingConfirmationPage = lazy(() => import('./screens/PaymentConfirmation/BillingConfirmationPage'));
const ItineraryDetailsFlightPage = lazy(() => import('./screens/ItineraryDetails/ItineraryDetailsFlightPage'));
const ItineraryDetailsPaymentPage = lazy(() => import('./screens/ItineraryDetails/ItineraryDetailsPaymentPage'));

// ozobi on URL possible for paymentType= any, in OPS
const isOzobi = window.location.href.includes('ozobi');

const RootLayout = ({ children }) => {
  return (
    <Suspense fallback={<Loader />}>
      <ModalMain>
        <Banner />
        <MarketingMessages>
          {/* HEADER */}
          {isOzobi ? <OzobiHeader /> : <Header />}

          {/* PAGE CONTENT */}
          <Content>{children}</Content>

          {/* FOOTER */}
          {isOzobi ? <OzobiFooter /> : <Footer />}

          {/* GOOGLE ONE TAP SCRIPT */}
          {/* <GoogleOneTapScript /> */}

          {/* <FetchUser /> */}
        </MarketingMessages>
      </ModalMain>
    </Suspense>
  );
};

const AppRouter = () => (
  <BrowserRouter>
    <RootLayout>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path={routes.FLIGHT_ITINERARY_DETAILS} element={<ItineraryDetailsFlightPage />} />
          <Route path={routes.BOOK_FLIGHT_SUCCESS} element={<BookFlightSuccessPage />} exact />
          <Route path={routes.BOOK_FLIGHT} element={<BookFlightPage />} />
          <Route path={routes.SEARCH} element={<FlightsPage />} />
          <Route path={routes.BILLING_CONFIRMATION} element={<BillingConfirmationPage />} />
          <Route path={routes.ITINERARY_DETAILS} element={<ItineraryDetailsPaymentPage />} />
          <Route path={routes.SUCCESSFUL_CONFIRMATION} element={<SuccessfulConfirmation />} />
          <Route path={routes.NOT_FOUND} element={<NotFound />} />
        </Routes>
      </Suspense>
    </RootLayout>
  </BrowserRouter>
);

export default compose(withThemeProvider({ mode: 'travelopod' }))(AppRouter);
