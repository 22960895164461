import { MARKETING_BASE_URL } from './config';

export const WEEK_DAY_DATE_FORMAT = 'ddd, DD MMM';

export const FLIGHT_DATE_FORMAT = 'ddd, MMM D';
export const FLIGHT_DATE_YEAR_FORMAT = 'ddd, MMM D, YYYY';
export const FLIGHT_DAY_MONTH_FORMAT = 'MMM D';
export const HOUR_12_TIME_FORMAT = 'hh:mm a';

export const AIRPORT_CODE_REG_EXP = /^[A-Za-z\s-]+$/;

export const FUSED_DATE_FORMAT = 'YYYYMMDD';

export const UpgradePackages = {
  markets: ['USA Domestic', 'USA to Canada', 'Canada to Canada', 'Canada to USA'],
  plans: {
    basic: {
      cost: 0,
      value: 'UPGRADE_BASIC',
      title: 'Basic Economy',
      descriptions: [
        'No complimentary seat selection',
        'No refunds or flight changes',
        'Board in last group',
        'No upgrade options',
        'No full size carry-on bag (personal item only)',
      ],
    },
    standard: {
      cost: 35,
      value: 'UPGRADE_STANDARD',
      title: 'Standard Economy',
      descriptions: [
        'Choose complimentary seats, if available',
        'Change your flight if your plans change, for a fee',
        'Bring a full-sized carry-on bag on board',
        'Have an option to upgrade',
      ],
    },
  },
};

export const SupportPackages = {
  markets: ['USA to '],
  plans: {
    standard: {
      cost: 0,
      value: 'SUPPORT_STANDARD',
      title: 'Standard',
      descriptions: {
        cancellation: '!Regular',
        postSales: '!Regular',
        rescheduling: '!None',
        priceDrop: '!None',
      },
    },
    premium: {
      cost: 10,
      value: 'SUPPORT_PREMIUM',
      title: 'Premium',
      descriptions: {
        cancellation: 'Free',
        postSales: 'Faster',
        rescheduling: 'Assistance',
        priceDrop: '!None',
      },
    },
    platinum: {
      cost: 34,
      value: 'SUPPORT_PLATINUM',
      title: 'Platinum',
      descriptions: {
        cancellation: 'Free',
        postSales: 'The Fastest',
        rescheduling: 'Assistance',
        priceDrop: 'Money back',
      },
    },
  },
};

export const SupportUpgradeMap = {
  UPGRADE_BASIC: UpgradePackages.plans.basic,
  UPGRADE_STANDARD: UpgradePackages.plans.standard,
  SUPPORT_STANDARD: SupportPackages.plans.standard,
  SUPPORT_PREMIUM: SupportPackages.plans.premium,
  SUPPORT_PLATINUM: SupportPackages.plans.platinum,
};

export const TripType = {
  ROUND_TRIP: 'ROUND_TRIP',
  ONE_WAY: 'ONE_WAY',
};

export const ServiceClass = {
  ECONOMY: {
    value: 'ECONOMY',
    label: 'Economy',
  },
  PREMIUM_ECONOMY: {
    value: 'PREMIUM_ECONOMY',
    label: 'Premium Economy',
  },
  BUSINESS: {
    value: 'BUSINESS',
    label: 'Business',
  },
  FIRST: {
    value: 'FIRST',
    label: 'First',
  },
};

export const NO_FLIGHTS_ERROR_MSG = 'There are no flights available on these dates!';

export const CARD_TYPES_FROM_SERVER = {
  VISA: {
    name: 'Visa',
    imageName: 'visa',
  },
  'MASTER-CARD': {
    name: 'Mastercard',
    imageName: 'mastercard',
  },
  MASTER_CARD: {
    name: 'Mastercard',
    imageName: 'mastercard',
  },
  DISCOVER: {
    name: 'Discover',
    imageName: 'discover',
  },
  'AMERICAN-EXPRESS': {
    name: 'American Express',
    imageName: 'american_express',
  },
};

// for backward capability with old ibe
CARD_TYPES_FROM_SERVER.AMEX = {
  name: 'American Express',
  imageName: 'american_express',
};
CARD_TYPES_FROM_SERVER.MASTER = {
  name: 'Mastercard',
  imageName: 'mastercard',
};

export const CARD_TYPE = {
  VISA: 'visa',
  MASTER_CARD: 'mastercard',
  DISCOVER: 'discover',
  AMERICAN_EXPRESS: 'american-express',
};

export const DATA_ABSENCE_STRING = 'No Data';

export const PST_FORMAT = {
  PST_TIME_ZONE: 'America/Los_Angeles',
  PST_TIME_FORMAT: 'ddd MMM DD YYYY, hh:mmA',
};

export const MARKETING = MARKETING_BASE_URL;

export const SHORT_DATE_FORMAT = 'MMM DD';

export const MARKETING_TERMS_AND_CONDITIONS = `${MARKETING}/terms-conditions/`;
export const MARKETING_PRIVACY_POLICY = `${MARKETING}/privacy-policy/`;

export const MARKETING_URL_FLIGHTS = `${MARKETING}/flights/`;
export const MARKETING_URL_HOTELS = `https://www.booking.com/?aid=870169/?`;
export const MARKETING_URL_CARS = `${MARKETING}/cars/`;
export const MARKETING_URL_TRIP_PLANNER = `http://planner.travelopod.com/`;
export const MARKETING_URL_CORPORATE_TRAVEL = `${MARKETING}/more/corporate-travel/`;
export const MARKETING_URL_GROUP_TRAVEL = `${MARKETING}/more/group-travel/`;
export const MARKETING_URL_BLOG = `${MARKETING}/blog/`;

export const MARKETING_URL_ABOUT_US = `${MARKETING}/aboutus/`;

export const MARKETING_URL_BOOKINGRULES = `${MARKETING}/bookingrules/`;

export const BAGGAGE_FEE_LINK = 'https://bags.amadeus.com/Display.aspx?a=';

export const REG_EXPS = {
  ONLY_NUMBERS: new RegExp(/^\d*$/),
  ALL_NUMBERS: new RegExp(/\d/),
  ONLY_NUMBERS_AND_LETTERS: new RegExp(/^[0-9A-Za-z]*$/),
  EXCEPT_NUMBER_REG_EXP: /^[A-Za-z!"#$%&'\(\)*+,-.\/:;<=>?@\[\\\]^_`\{\|\}~]*$/,
  ONLY_NUMBERS_BASE: /^(\(?\+?\d*\)?)?\d*$/,
  AMOUNT_REG_EXP: /^((?!(0))\d*)$/,
  NAME_REG_EXP: /^([a-zA-Z]*-?[a-zA-Z]+)+( [a-zA-Z]+-?[a-zA-Z]*)?$/,
  TEXT_REG_EXP: /^[a-zA-Z0-9]+(\s[a-zA-Z0-9]+)*$/,
  DOCUMENT_REG_EXP: /^[^а-яА-ЯЁёЇїІіЄєҐґ]{2,1000}$/,
  ALTERNATE_NUMBERS: /^[0-9\-,+]*$/,
  PHONE_NUMBER: /^[0-9\-+()\s]*$/,
  EMAIL: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
  ONLY_TEXT: new RegExp(/^[A-Za-z-\s]*$/),
  ONLY_LATIN: new RegExp(/^[A-Za-z]*$/),
  POSTAL_CODE: new RegExp(/^[A-Za-z0-9]*$/),
  TEXT_AND_NUMBERS: new RegExp(/^[A-Za-z0-9\s]*$/),
  USA_ZIP_CODE: /^(?!0+$)\d{5}$/,
};

export const PNR_IMPORT_TYPES = {
  manualImport: 'MANUAL_IMPORT',
  automaticImport: 'AUTOMATIC_IMPORT',
};

export const FLIGHT_SEGMENT_TYPE = {
  SEGMENT: 'SEGMENT',
  LAYOVER: 'LAYOVER',
};

export const SHORT_MONTH_DATE_FORMAT = 'MMM DD, YYYY';

export const MILLISECONDS_IN_SECOND = 1000;

export const PAGINATION_ITEMS_PER_PAGE = 20;

export const TRIP_TYPE = {
  oneWay: 'ONE_WAY',
  roundTrip: 'ROUND_TRIP',
};

export const CAMPAIGN = {
  TRAVELOPOD: 'travelopod',
  TRAVELOPOD_B: 'travelopodB',
  FLYKART: 'flykart',
};

export const MKT_SOURCE = ['canada'];

export const CURRENCY_INFO = {
  canada: {
    region: 'canada',
    currencySymbol: 'C$',
    currencyCode: 'CAD',
  },
  us: {
    region: 'us',
    currencySymbol: '$',
    currencyCode: 'USD',
  },
};
export const domains = {
  A: 'A',
  B: 'B',
};
