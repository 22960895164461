export const MARKETING_VIDEO_STATUS = 'marketingVideo/playStatus';

const initialState = {
  playStatus: false,
};

const marketingVideoReducer = (state = initialState, action) => {
  if (action.type === MARKETING_VIDEO_STATUS) {
    return { playStatus: action.value };
  }

  return state;
};

export const saveMarketingVideoStatus = (playStatus) => {
  return {
    type: MARKETING_VIDEO_STATUS,
    value: playStatus,
  };
};

export default marketingVideoReducer;
