import { withTheme } from 'travelopod-components';
import { compose, withHandlers, withProps } from 'recompose';

import withRouter from './withRouter';
import { domains } from '../constants/global';

export default () =>
  compose(
    withRouter,
    withTheme,
    withHandlers({
      getDomainInfo: (props) => () => {
        const { params, theme } = props;
        let domain = 'A';

        return {
          name: params.compain || '',
          domain,
          theme: theme && theme.mode,
        };
      },
    }),
    withProps((props) => ({
      isOffline: () => props.getDomainInfo().domain === domains['A'],
    })),
  );
