export const triggerMetaPixelPageView = () => triggerMetaPixelEvent({ eventType: 'track', eventName: 'PageView' });

export const triggerMetaPixelCallNow = () => triggerMetaPixelEvent({ eventType: 'track', eventName: 'Contact' });

export const triggerMetaPixelSearchFlight = () => triggerMetaPixelEvent({ eventType: 'track', eventName: 'Search' });

export const triggerMetaPixelBookingConfirmation = (bookingData) => {
  triggerMetaPixelEvent({ eventType: 'track', eventName: 'Contact', values: bookingData });

  sessionStorage.setItem('metaPixelPurchaseTriggered', true);

  return;
};

const triggerMetaPixelEvent = ({ eventType, eventName, values = null }) => {
  values ? fbq(eventType, eventName, values) : fbq(eventType, eventName);
};
