import './index.css';

import React, { useEffect, useState } from 'react';

const Toastr = ({ message, type }) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(false);
    }, 5000);
  }, []);

  return (
    <div className={`toastr ${type} ${isVisible ? 'is-visible' : ''}`}>
      <div className="toastr-text-message">{message}</div>
      <button className="toastr-close-icon" onClick={handleClose}>
        x
      </button>
    </div>
  );
};

export default Toastr;
