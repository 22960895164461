import queryString from 'query-string';
import { compose, withHandlers, withProps } from 'recompose';

import withCallTrackingUrl from './withCallTrackingUrl';
import withRouter from './withRouter';

const validatePassengers = (values) => {
  if (values.adults == 0) {
    values.adults = '1';
  }
  return values;
};

export const fromUrlToValue = (url, defaultFilters) => {
  return validatePassengers({ ...defaultFilters, ...queryString.parse(url, { arrayFormat: 'bracket' }) });
};

export const fromValueToUrl = (values, value, defaultFilters) => {
  return queryString.stringify({ ...defaultFilters, ...values, ...value }, { arrayFormat: 'bracket' });
};

export default (mapper) =>
  compose(
    withRouter,
    withCallTrackingUrl(),
    withProps((props) => ({
      queryFilters: fromUrlToValue(`${props.location.search}`, mapper ? mapper(props) : {}),
    })),
    withHandlers({
      updateFilters: (props) => (nextFilters) => {
        const defaultFilters = mapper ? mapper(props) : {};
        const currentQueryString = queryString.extract(props.location.search);
        const nextQueryString = fromValueToUrl(
          props.queryFilters,
          nextFilters,
          props.parseValuesToValues(defaultFilters),
        );

        if (currentQueryString !== nextQueryString) {
          props.navigate({
            pathname: props.location.url,
            search: nextQueryString,
          });
        }
      },
      upsertFilters: (props) => (nextFilters) => {
        const defaultFilters = mapper ? mapper(props) : {};
        const currentQueryString = queryString.extract(props.location.search);
        const nextQueryString = fromValueToUrl(nextFilters, {}, props.parseValuesToValues(defaultFilters));

        if (currentQueryString !== nextQueryString) {
          props.navigate({
            pathname: props.location.url,
            search: nextQueryString,
          });
        }
      },
    }),
  );
