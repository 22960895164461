// @flow
import { put, takeEvery } from 'redux-saga/effects';

import api from '../../api';
import {
  UPDATE_PHONE,
  UPDATE_PHONE_ERROR,
  UPDATE_PHONE_SUCCESS,
  getDefaultNumber,
} from '../modules/responseTap/phoneData';
import { formatPhone } from '../../helpers/phone';
import { fromUrlToValue } from '../../Hocs/withFilters';

// refactor check for cabin type
const getCabinType = (cabin) => {
  switch (cabin.toLowerCase()) {
    case 'economy':
      return 'economy';
    case 'premium_economy':
      return 'premium';
    case 'first':
      return 'first';
    case 'business_class':
      return 'business';
    default:
      return 'economy';
  }
};

const collectCallTrackingParams = ({ destinationIATA, cabin }) => {
  // get page path and queryParams from location.pathname(URL)
  const [pageSource, pathName] = window.location.pathname.split('?');
  const { search } = window.location;

  // get tpodtfn and utmSource from URL queryParams
  const { utm_source: utmSource, tpodtfn } = fromUrlToValue(search || pathName || '');

  return {
    ...(utmSource && { utmSource }),
    ...(pageSource && { pageSource }),
    ...(destinationIATA && { destinationIATA }),
    ...(cabin && { cabinType: getCabinType(cabin) }),
    domain: 'travelopod',
    ...(tpodtfn && { tpodtfn }),
  };
};

/**
 * @param {string} contains destinationAirport and cabinClass
 */
function* updatePhoneTFN({ data }) {
  // TO_DO : change function by changing the {data} object
  try {
    let tfnPhone;

    const sessionTfn = sessionStorage.getItem('session-phone-number');
    // ------ check if call-tracking API call is required ------
    if (sessionTfn) {
      tfnPhone = sessionTfn;
    } else {
      const { destinationIATA, cabin } = data;
      // use call-tracking API iff session-phone-number is NOT available
      const tfnParams = collectCallTrackingParams(data);
      const response = yield api().phoneNumber.getPhoneNumber(tfnParams);
      tfnPhone = response.data.data;
    }

    // send call-tracking TFN result from API
    yield put({ type: UPDATE_PHONE_SUCCESS, result: formatPhone(tfnPhone) });
  } catch (err) {
    const error = err.response.data.error;

    yield put({ type: UPDATE_PHONE_ERROR, error, result: getDefaultNumber() });
  }
}

export default function* (): Generator<*, *, *> {
  yield takeEvery(UPDATE_PHONE, updatePhoneTFN);
}
