import CONTACT_PHONE_MAP from '../../../constants/responseTap';
import { formatPhone } from '../../../helpers/phone';

export const UPDATE_PHONE = 'phoneData/UPDATE_PHONE';
export const UPDATE_PHONE_SUCCESS = 'phoneData/UPDATE_PHONE_SUCCESS';
export const UPDATE_PHONE_ERROR = 'phoneData/UPDATE_PHONE_ERROR';

export function updatePhoneTFN(data) {
  return { type: UPDATE_PHONE, data };
}

export function setBookingFailedPhone() {
  return {
    type: UPDATE_PHONE_SUCCESS,
    result: formatPhone(CONTACT_PHONE_MAP.BOOKING_FAILED_PHONE),
  };
}

export function setBookingPhone() {
  return {
    type: UPDATE_PHONE_SUCCESS,
    result: formatPhone(CONTACT_PHONE_MAP.BOOKING_PHONE),
  };
}

export const getDefaultNumber = () =>
  formatPhone(sessionStorage.getItem('session-phone-number') || CONTACT_PHONE_MAP.DEFAULT_PHONE);

const initialState = {
  phone: getDefaultNumber(),
};

// selectors
export const getPhoneDataSelector = (state) => {
  const url = new URL(window.location.href);
  const phoneNum = url.searchParams.get('phoneNumber');

  if (phoneNum) {
    return { phone: phoneNum };
  }

  return state.responseTap.phoneData;
};

export default function phoneData(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PHONE_SUCCESS:
    case UPDATE_PHONE_ERROR:
      return {
        ...state,
        phone: action.result,
      };
    default:
      return state;
  }
}
