/* eslint-disable react/prop-types */
import './index.css';

import React from 'react';
import { User } from 'react-feather';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router';

import { IBE_URL, USER_AUTH_URL } from '../../constants/config';
import { saveAuthUser } from '../../store/modules/authUser/user';
import { getGoogleAuthUrl } from '../../helpers/getGoogleAuthUrl';

const LoginLogoutBtn = (props) => {
  const { user, saveAuthUser, showDropdown = false } = props;
  const location = useLocation();
  const params = useParams();

  const logoutHandler = async () => {
    try {
      await fetch(`${USER_AUTH_URL}/session/logout`, {
        credentials: 'include',
      });

      saveAuthUser(null);
    } catch (error) {
      saveAuthUser(null);
      console.log('---> Logout Error: ', error);
    }
  };

  const loginHandler = () => {
    console.log('location: ', location);
    console.log('params: ', params);
    window.location.href = getGoogleAuthUrl(`${IBE_URL}${location.pathname}${location.search}`);
  };

  return (
    <div className="login-logout-mobile-btn-wrapper login-logout-btn">
      <button
        className={showDropdown ? 'login-button-side-menu-link' : 'login-button-desktop'}
        onClick={!user ? loginHandler : logoutHandler}
      >
        <User />
        {!user ? 'Login' : `${user.firstName}`}
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { user: state.authUser.user };
};

export default connect(mapStateToProps, { saveAuthUser })(LoginLogoutBtn);
