import queryString from 'query-string';
import { compose, withHandlers } from 'recompose';
import { connect as reduxConnect } from 'react-redux';

import withDomain from './withDomain';
import withRouter from './withRouter';
import { EXCHANGE_RATE_API } from '../constants/config';
import { MARKETING, MKT_SOURCE, CURRENCY_INFO } from '../constants/global';

// n hour gap for currency conversion rate refresh
const refreshTime_currencyRates = 1 * (60 * 60 * 1000);

export const fromUrlToValue = (url, defaultFilters) => {
  return { ...defaultFilters, ...queryString.parse(url, { arrayFormat: 'bracket' }) };
};

export const fromValueToUrl = (values, value, defaultFilters) => {
  return queryString.stringify({ ...defaultFilters, ...values, ...value }, { arrayFormat: 'bracket' });
};

const setLatestConversion = (source) =>
  fetch(`${EXCHANGE_RATE_API}?pair=USD_${source.currencyCode}`, { method: 'GET' })
    .then((res) => res.json())
    .then((res) => {
      const source_conversion_old = localStorage.getItem('source_conversion');
      let source_conversion = (source_conversion_old && JSON.parse(source_conversion_old)) || {};
      sessionStorage.removeItem('isfetchingConversionRate');
      source_conversion[source.region] = {
        rate: res.exchange_rate,
        time: new Date().getTime(),
      };
      localStorage.setItem('source_conversion', JSON.stringify(source_conversion));
      return res.exchange_rate;
    });

export default (mapper) =>
  compose(
    withRouter,
    reduxConnect(),
    withDomain(),
    withHandlers({
      parseValuesToValues:
        ({ location }) =>
        (mixin) => {
          const [, pathname] = location.pathname.split('?');
          const { search } = location;
          const { tpodmkt, tpodtfn, utm_device, utm_medium, utm_source, utm_term, mkt_source, phoneNumber } =
            fromUrlToValue(search || pathname || '');

          const urlValues = {
            ...(mixin && { ...mixin }),
            ...(tpodmkt && { tpodmkt }),
            ...(tpodtfn && { tpodtfn }),
            ...(utm_device && { utm_device }),
            ...(utm_medium && { utm_medium }),
            ...(utm_source && { utm_source }),
            ...(utm_term && { utm_term }),
            ...(mkt_source && { mkt_source }),
            ...(phoneNumber && { phoneNumber }),
          };

          return urlValues;
        },
      isMKTSource:
        ({ location }) =>
        () => {
          const [, pathname] = location.pathname.split('?');
          const { search } = location;
          // default market is usa
          let { mkt_source = 'us' } = fromUrlToValue(search || pathname || '');
          let mkt_unknown = false;
          // get a valid mkt_source
          if (!(mkt_source in CURRENCY_INFO)) {
            mkt_source = 'us';
            mkt_unknown = true;
          }

          let source_conversion =
            localStorage.getItem('source_conversion') && JSON.parse(localStorage.getItem('source_conversion'));
          let isfetchingConversionRate =
            sessionStorage.getItem('isfetchingConversionRate') &&
            JSON.parse(sessionStorage.getItem('isfetchingConversionRate'));
          let conversionRate = 1;

          if (mkt_source !== 'us') {
            if (
              source_conversion &&
              source_conversion[mkt_source] &&
              new Date().getTime() - source_conversion[mkt_source].time < refreshTime_currencyRates
            ) {
              conversionRate = source_conversion[mkt_source].rate;
            } else {
              // isfetchingConversionRate is NOT true -> fetch API, store data
              if (isfetchingConversionRate !== true) {
                sessionStorage.setItem('isfetchingConversionRate', true);
                conversionRate = setLatestConversion(CURRENCY_INFO[mkt_source]);
              }
              // isFetchingConversionRate is true -> wait for API data, check every 100ms
              else {
                const timeout = setTimeout(() => {
                  source_conversion =
                    localStorage.getItem('source_conversion') && JSON.parse(localStorage.getItem('source_conversion'));
                  if (
                    source_conversion &&
                    new Date().getTime() - source_conversion[mkt_source].time < refreshTime_currencyRates
                  ) {
                    clearTimeout(timeout);
                  }
                }, 100);
              }
            }
          }
          return {
            ...CURRENCY_INFO[mkt_source],
            conversionRate,
            mkt_unknown,
          };
        },
    }),
    withHandlers({
      buildMarketingUrl:
        (props) =>
        (link = MARKETING, additionalParams) => {
          if (link.includes(MARKETING)) {
            const data = {
              ...(additionalParams && { ...additionalParams }),
            };

            const allParams = props.parseValuesToValues(data);

            const { mkt_source = '', ...params } = allParams;
            const paramsString = fromValueToUrl(params);
            const parsedLink = link.split('/');
            MKT_SOURCE.forEach((el) => mkt_source === el && parsedLink.splice(3, 0, el));

            return parsedLink.join('/') + (paramsString && `?${paramsString}`);
          } else {
            return link;
          }
        },
    }),
  );
