import React from 'react';
import { compose } from 'recompose';
import { ToastContainer } from 'react-toastify';
import { connect as reduxConnect } from 'react-redux';
import {
  CallBookingModal,
  DefaultNormalModal,
  DesktopLarge,
  Indent,
  Mobile,
  RefreshResultsModal,
  Tablet,
  Text,
} from 'travelopod-components';

import i18n from '../locales/i18next';
import {
  closeDefaultModals,
  get10MinuteModalStatus,
  get25SecModalStatus,
  getCallUsData,
  getCallUsFooterStatus,
  getIsBannersShown,
  getOneDayModalStatus,
  getPopupConfigData,
  getSameDayModalStatus,
  getSuperDealCallLaterStatus,
  getSuperDealModalStatus,
  toggle10MinuteModal,
  toggle25SecModal,
  toggleCallUsFooterModal,
  toggleNoSeatsModal,
  togglePriceChangedModal,
} from '../store/modules/modalGeneral';
import config from '../constants/config';
import Toastr from '../components/Toastr';
import withModal from '../Hocs/withModal';
import withRouter from '../Hocs/withRouter';
import withDomain from '../Hocs/withDomain';
import allIconAssets from '../assets/icons';
import withRefetch from '../Hocs/withRefetch';
import withFilters from '../Hocs/withFilters';
import { formatPhone } from '../helpers/phone';
import { isDelayed } from '../helpers/defaultPopup';
import ExitIntentPopup from './ExitIntentPopup/ExitIntentPopup';
import callNowBannerImage from '../assets/images/callNowBannerImage.svg';
import ResponseTapPhone from '../components/ResponseTap/ResponseTapPhone';
import { getPhoneDataSelector } from '../store/modules/responseTap/phoneData';
import TrustpilotHorizontal from '../components/Trustpilot/TrustpilotHorizontal';

const ModalMain = (props) => {
  const {
    children,
    queryFilters,
    callUsData,
    oneDayModalStatus,
    sameDayModalStatus,
    superDealModalStatus,
    superDealCallLaterStatus,
    closeDefaultModals,
    callUs25SecModalStatus: defaultPopupStatus,
    modal10MinuteStatus,
    toggle10MinuteModal,
    location,
    callUsStatus: footerPopupStatus,
    isBannersShown,
    phoneData,
    fakeflightResponse,
    popupConfigData = {},
    submitFakeFlightEnquiry,
  } = props;

  const { call_inline } = allIconAssets;
  const isSearchPage = location.pathname.includes('/search');

  // default values for default and footer popup
  const defaultConfig = {
    whyCallUsHeader: 'Why Call us',
    whyCallUsPoints: ['{{destination}} Phone Special Deal', 'Get upto $300 lower than online by calling us'],
    bannerHighlight1: 'Based on 20k+ reviews',
    bannerHighlight2: 'Loved by Millions',
    bannerHighlight3: '13 years of excellence',
    review: 'Glad I called! Got a great flight deal and a better route as well.',
    reviewer: 'Vicky N , LA',
  };

  // default values for callNow and callLater Popups
  const superDealConfig = {
    ...defaultConfig,
    whyCallUsPoints: ['{{fare}} Deal Valid Only On Call', 'Not Valid For Online Sale'],
  };

  let config = {};
  if (Object.keys(popupConfigData).length) {
    switch (true) {
      case superDealCallLaterStatus:
        config = popupConfigData.callLater;
        break;
      case superDealModalStatus:
        config = popupConfigData.callNow;
        break;
      case defaultPopupStatus:
        config = popupConfigData.defaultPopup;
        break;
      case footerPopupStatus:
        config = popupConfigData.footerPopup;
        break;
      default:
        config = {};
    }
  }

  return (
    <>
      <ToastContainer />

      {isSearchPage && (
        <>
          <ExitIntentPopup {...props} />

          {fakeflightResponse && (
            <div>
              {fakeflightResponse.fulfilled && <Toastr message="Enquiry form submitted successfully!" type="success" />}
              {fakeflightResponse.rejected && <Toastr message="Error submitting form" type="error" />}
            </div>
          )}

          {/* CALL NOW MODAL, CALL LATER MODAL, FOOTER MODAL, DEFAULT POPUP MODAL  */}
          {isBannersShown && (
            <CallBookingModal
              superDeal={superDealModalStatus}
              superDealCallLater={superDealCallLaterStatus}
              isOpen={
                superDealModalStatus ||
                superDealCallLaterStatus ||
                footerPopupStatus ||
                (defaultPopupStatus &&
                  !isDelayed() &&
                  !superDealCallLaterStatus &&
                  !superDealModalStatus &&
                  !footerPopupStatus) //condition for defaultPopup
              }
              onClose={closeDefaultModals} //single funct. to close all the modals
              searchFlightsResponse={callUsData.searchFlightsResponse}
              modalDataElement={
                superDealCallLaterStatus ? 'call-later-booking-modal-wrapper' : 'call-booking-modal-wrapper'
              }
              className={
                superDealCallLaterStatus
                  ? 'call-booking-modal-wrapper call-later-booking-modal-wrapper'
                  : 'call-booking-modal-wrapper'
              }
              config={{
                ...(superDealModalStatus || superDealCallLaterStatus ? superDealConfig : defaultConfig),
                ...config,
              }}
              callBookingModalAssets={allIconAssets}
              submitFakeFlightEnquiry={submitFakeFlightEnquiry}
              queryFilters={queryFilters}
              callNowBannerImage={callNowBannerImage}
              callNowClickEvent={
                superDealModalStatus
                  ? () => (window.location.href = formatPhone(phoneData.phone, 'RFC3966'))
                  : undefined
              }
              destination={callUsData && callUsData.destination}
              popupType={superDealCallLaterStatus ? 'callLater' : 'callNow'}
            >
              {/*-----SENDING CHILDREN FOR CALLUS POPUP-----*/}

              {/* PHONE NUMBER */}
              <div className="call-modal-phone-wrapper">
                <img src={call_inline} />
                <Text
                  weight="bold"
                  color="orange"
                  className="super-deal-modal-block-response-tap default-modal-component"
                  as={(props) => (
                    <ResponseTapPhone
                      {...{ ...props, callUsEventId: 'call-us-phone-superdeal-popup' }}
                      renderFrom="phoneNumberSuperDeal"
                    />
                  )}
                  title={i18n.t('components.header.phone')}
                />
              </div>

              {/* CALL NOW BTN */}
              <a href={formatPhone(phoneData.phone, 'RFC3966')}>
                <button className="call-booking-button">Call Now</button>
              </a>

              {/*-----CHILDREN ELEMENT ENDS HERE----- */}
            </CallBookingModal>
          )}

          {/* SAME DAY MODAL */}
          <DefaultNormalModal
            className="same-day-modal"
            onClose={closeDefaultModals}
            isOpen={sameDayModalStatus}
            phone={formatPhone(phoneData.phone, 'RFC3966')}
          >
            <Indent className="same-day-modal-block-with-girl-wrapper">
              <DesktopLarge>
                <Indent className="same-day-modal-block-with-girl" />
              </DesktopLarge>
              <Tablet>
                <Indent className="same-day-modal-block-with-girl" />
                <Indent className="same-day-modal-block-with-girl-shadow" />
              </Tablet>
              <Mobile>
                <Indent className="same-day-modal-block-with-girl" />
                <Indent className="same-day-modal-block-with-girl-shadow" />
              </Mobile>
            </Indent>
            <Text
              weight="bold"
              color="orange"
              className="same-day-modal-block-response-tap default-modal-component"
              as={(props) => <ResponseTapPhone {...props} renderFrom="phoneNumberDefault" />}
              title={i18n.t('components.header.phone')}
            />
            <TrustpilotHorizontal />
          </DefaultNormalModal>

          {/* TRAVEL WITHIN 1 DAY MODAL */}
          <DefaultNormalModal
            className="travel-within-1-day-modal"
            onClose={closeDefaultModals}
            isOpen={oneDayModalStatus}
            title="Travel within 1 day can only be booked over phone"
            label="We have special last minute phone deals to be booked on phone."
            phone={formatPhone(phoneData.phone, 'RFC3966')}
          >
            <Indent className="same-day-modal-block-with-girl-wrapper">
              <DesktopLarge>
                <Indent className="same-day-modal-block-with-girl one-day" />
              </DesktopLarge>
              <Tablet>
                <Indent className="same-day-modal-block-with-girl one-day" />
                <Indent className="same-day-modal-block-with-girl-shadow one-day" />
              </Tablet>
              <Mobile>
                <Indent className="same-day-modal-block-with-girl one-day" />
                <Indent className="same-day-modal-block-with-girl-shadow one-day" />
              </Mobile>
            </Indent>
            <Text
              weight="bold"
              color="orange"
              className="same-day-modal-block-response-tap default-modal-component"
              as={(props) => (
                <ResponseTapPhone
                  {...{ ...props, callUsEventId: 'call-us-phone-same-day-popup' }}
                  renderFrom="phoneNumberDefault"
                />
              )}
              title={i18n.t('components.header.phone')}
            />
            <TrustpilotHorizontal />
          </DefaultNormalModal>

          {/* REFRESH RESULT MODAL */}
          <RefreshResultsModal
            isOpen={modal10MinuteStatus}
            shouldCloseOnOverlayClick={false}
            onClose={toggle10MinuteModal}
          />
        </>
      )}

      {children}
    </>
  );
};

export default compose(
  withModal,
  withRouter,
  withFilters(),
  withDomain(),
  withRefetch(() => {
    return {
      submitFakeFlightEnquiry: (data) => ({
        fakeflightResponse: {
          url: `${config.baseURL}/api/enquiries/fakeflight`,
          method: 'POST',
          body: JSON.stringify({
            ...data,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        },
      }),
    };
  }),
  reduxConnect(
    (state) => ({
      callUsData: getCallUsData(state),
      callUsStatus: getCallUsFooterStatus(state),
      oneDayModalStatus: getOneDayModalStatus(state),
      sameDayModalStatus: getSameDayModalStatus(state),
      superDealModalStatus: getSuperDealModalStatus(state),
      superDealCallLaterStatus: getSuperDealCallLaterStatus(state),
      callUs25SecModalStatus: get25SecModalStatus(state),
      modal10MinuteStatus: get10MinuteModalStatus(state),
      isBannersShown: getIsBannersShown(state),
      popupConfigData: getPopupConfigData(state),
      phoneData: getPhoneDataSelector(state),
    }),
    {
      toggleCallUsFooterModal,
      closeDefaultModals,
      toggle25SecModal,
      toggle10MinuteModal,
      toggleNoSeatsModal,
      togglePriceChangedModal,
    },
  ),
)(ModalMain);
