import './ExitIntentPopup.css';
import 'react-toastify/dist/ReactToastify.css';
import './reactToastifyOverride.css';

import { withFormik } from 'formik';
import moment from 'moment';
import React from 'react';
import { toast } from 'react-toastify';
import { compose, lifecycle, withProps, withState } from 'recompose';
import { Desktop, Link, Mobile, Modal, PriceCalendarExitPopup, Text } from 'travelopod-components';

import { getAirportsFrom, getAirportsTo, getInitialAirports } from '../../api/modules/searchFlights';
import TrustpilotCarousel from '../../components/Trustpilot/TrustpilotCarousel';
import TrustpilotHorizontal from '../../components/Trustpilot/TrustpilotHorizontal';
import withRefetch from '../../Hocs/withRefetch';
import { mapPropsToValues, validationSchema } from './form';

const startOfDay = (date) => {
  return moment
    .utc(date * 1000)
    .startOf('day')
    .add('12', 'hours')
    .format('YYYY-MM-DD');
};

const Msg = () => (
  <>
    <div>Thank you for entering! </div>
    <p>Check out our greatest flight deals here.</p>
  </>
);

const showToast = () => {
  toast.success(<Msg />, {
    position: toast.POSITION.BOTTOM_CENTER,
    autoClose: 5000,
    pauseOnHover: false,
  });
};

const PC = compose(
  withRefetch(() => ({
    getAirportsFrom,
    getAirportsTo,
  })),
  withFormik({
    mapPropsToValues,
    validateOnChange: true,
    validateOnMount: true,
    handleSubmit: ({ tripType, serviceClass, ...values }, { props }) => {
      props.submitEmailCampaign({
        ...values,
        origin: values.origin.code,
        destination: values.destination.code,
        departureDate: startOfDay(values.departureDate),
        arrivalDate: values.arrivalDate ? startOfDay(values.arrivalDate) : null,
      });
      props.onClose();
      showToast();
    },
    validationSchema,
  }),
  withState('initialValues', 'onInit', null),
  withProps((props) => ({
    onFromChange: (_, { value }) => {
      return props.getAirportsFrom(value);
    },
    onToChange: (_, { value }) => {
      return props.getAirportsTo(value);
    },
  })),
  lifecycle({
    async componentDidMount() {
      const { values, setFieldValue } = this.props;

      if (values.origin && values.destination) {
        const result = await getInitialAirports(values.origin, values.destination);

        setFieldValue('origin', result[0]);
        setFieldValue('destination', result[1]);
      }
    },
  }),
)(PriceCalendarExitPopup);

export default function ExitIntentPopup(props) {
  const { onClose, exitIntentModalStatus, ...rest } = props;

  return (
    <Modal
      isOpen={exitIntentModalStatus}
      onClose={onClose}
      persist
      className="exit-popup-modal"
      size="huge"
      contentClassName="exit-popup-modal-content"
      btnCloseClassName="exit-popup-modal-btn"
      overlayClassName="exit-popup-modal-overlay"
      modalName="exit-intent-popup-modal"
    >
      <div className="exit-popup-headline">
        <Text as="h1" color="link" align="center">
          LET US SEND YOU BEST BARGAIN DEAL
        </Text>
        <Text as="h2" color="orange" align="center">
          & Enter you for a chance to win a free trip <span>($1000 Giveaway)</span>{' '}
        </Text>
      </div>
      <PC onClose={onClose} {...rest} />
      <Text className="exit-popup-link" align="right" ma="t">
        <Link href="https://www.travelopod.com/win-a-trip/terms-conditions" title="Terms &amp; Conditions" />
      </Text>
      <Desktop>
        <div className="exit-popup-modal-trustpilot-holder">
          <TrustpilotCarousel />
        </div>
      </Desktop>
      <Mobile>
        <div className="exit-popup-modal-trustpilot-holder">
          <TrustpilotHorizontal />
        </div>
      </Mobile>
    </Modal>
  );
}
