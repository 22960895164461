import { connect } from 'react-redux';
import { branch, compose, lifecycle, renderNothing, withStateHandlers } from 'recompose';

import {
  closeExitIntentModal,
  get25SecModalStatus,
  getExitIntentModalStatus,
  getPriceChangedModalStatus,
  openExitIntentModal,
} from '../../store/modules/modalGeneral';
import ExitIntentPopup from './ExitIntentPopup.view';

import withDomain from '../../Hocs/withDomain';
import withRefetch from '../../Hocs/withRefetch';

import { APP_DOMAIN, OPS_API_URL } from '../../constants/config';
import { markAsOpened, wasOpened } from '../../helpers/exitIntent';

export default compose(
  withDomain(),
  connect(
    (state) => ({
      callUs25SecModalStatus: get25SecModalStatus(state),
      priceChangedModalStatus: getPriceChangedModalStatus(state),
      exitIntentModalStatus: getExitIntentModalStatus(state),
    }),
    { openExitIntentModal, closeExitIntentModal },
  ),
  withRefetch(() => ({
    submitEmailCampaign: (data) => ({
      emailCampaign: {
        url: `${OPS_API_URL}/api/emailCampaign`,
        method: 'POST',
        body: JSON.stringify({
          ...data,
          source: APP_DOMAIN,
        }),
        force: true,
      },
    }),
  })),
  withStateHandlers(
    {
      isOpen: false,
    },
    {
      onClose:
        (_, { closeExitIntentModal }) =>
        () => {
          closeExitIntentModal();
          return { isOpen: false };
        },
      open:
        ({ isOpen }, { priceChangedModalStatus, noSeatsModalStatus, openExitIntentModal }) =>
        () => {
          if (wasOpened()) {
            return { isOpen };
          }
          if (priceChangedModalStatus || noSeatsModalStatus) {
            return { isOpen: false };
          }
          markAsOpened();
          openExitIntentModal();
          return { isOpen: true };
        },
    },
  ),
  lifecycle({
    componentDidMount() {
      const { open, navigate } = this.props;

      this.handlePopstate = () => {
        if (wasOpened()) {
          return true;
        }
        navigate(1);
        open();
        window.removeEventListener('popstate', this.handlePopstate);
      };

      this.handleMouseleave = (event) => {
        if (event.clientY < 0) {
          open();
          document.querySelector('body').removeEventListener('mouseleave', this.handleMouseleave);
        }
      };

      document.querySelector('body').addEventListener('mouseleave', this.handleMouseleave);
    },

    componentWillUnmount() {
      document.querySelector('body').removeEventListener('mouseleave', this.handleMouseleave);
    },
  }),
  branch((props) => !props.isOpen, renderNothing),
)(ExitIntentPopup);
