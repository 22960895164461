import { combineReducers } from 'redux';

import payment from './payment';
import authUser from './authUser/user';
import responseTap from './responseTap';
import modalGeneral from './modalGeneral';
import priceCalendar from './priceCalendar';
import marketingVideo from './marketingVideo/marketingVideo';
import saveInsuranceQuoteReducer from './insuranceQuote/save';

const appReducer = combineReducers({
  payment,
  priceCalendar,
  responseTap,
  modalGeneral,
  quote: saveInsuranceQuoteReducer,
  authUser,
  marketingVideo,
});

export default function (state, action) {
  return appReducer(state, action);
}
