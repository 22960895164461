import call_inline from './call_inline.svg';
import certificate from './certificate.svg';
import five_star from './five_star.svg';
import google_review from './google_review.svg';
import iPhone_SE from './iPhone_SE.svg';
import popular_woman from './popular_woman.svg';
import very_popular_topic from './very_popular_topic.svg';
import tick_icon from './tick.svg';
import cross_icon from './cross.svg';

// Exporting all the icons as an object
const allIconAssets = {
  call_inline,
  certificate,
  five_star,
  google_review,
  iPhone_SE,
  popular_woman,
  very_popular_topic,
  tick_icon,
  cross_icon,
};

export default allIconAssets;
