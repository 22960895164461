export const SAVE_CALLUS_DATA = 'modalGeneral/SAVE_CALLUS_DATA';
export const CHANGE_CALL_US_STATUS = 'modalGeneral/CHANGE_CALL_US_STATUS';
export const CHANGE_ONE_DAY_STATUS = 'modalGeneral/CHANGE_ONE_DAY_STATUS';
export const CHANGE_SAME_DAY_STATUS = 'modalGeneral/CHANGE_SAME_DAY_STATUS';
export const CHANGE_SUPER_DEAL_STATUS = 'modalGeneral/CHANGE_SUPER_DEAL_STATUS';
export const CLOSE_DEFAULT_MODALS = 'modalGeneral/CLOSE_DEFAULT_MODALS';
export const OPEN_SUPER_DEAL_MODAL = 'modalGeneral/OPEN_SUPER_DEAL_MODAL';
export const OPEN_SUPER_DEAL_CALL_LATER_MODAL = 'modalGeneral/OPEN_SUPER_DEAL_CALL_LATER_MODAL';
export const OPEN_ONE_DAY_MODAL = 'modalGeneral/OPEN_ONE_DAY_MODAL';
export const OPEN_SAME_DAY_MODAL = 'modalGeneral/OPEN_SAME_DAY_MODAL';
export const START_25_SEC_MODAL_COUNTDOWN = 'modalGeneral/START_25_SEC_MODAL_COUNTDOWN';
export const TRIGGER_25_SEC_MODAL_COUNTDOWN = 'modalGeneral/TRIGGER_25_SEC_MODAL_COUNTDOWN';
export const TOGGLE_25_SEC_MODAL = 'modalGeneral/TOGGLE_25_SEC_MODAL';
export const TOGGLE_10_MINUTE_MODAL = 'modalGeneral/TOGGLE_10_MINUTE_MODAL';
export const REFRESH_30_MINUTE_MODAL_TIMEOUT = 'modalGeneral/REFRESH_30_MINUTE_MODAL_TIMEOUT';
export const TOGGLE_PRICE_CHANGED_MODAL = 'modalGeneral/TOGGLE_PRICE_CHANGED_MODAL';
export const TOGGLE_NO_SEATS_MODAL = 'modalGeneral/TOGGLE_NO_SEATS_MODAL';
export const SAVE_PHONE_DATA = 'modalGeneral/SAVE_PHONE_DATA';
export const SAVE_VERIFY_BOOKING_DATA = 'modalGeneral/SAVE_VERIFY_BOOKING_DATA';
export const OPEN_EXIT_INTENT_MODAL = 'modalGeneral/OPEN_EXIT_INTENT_MODAL';
export const CLOSE_EXIT_INTENT_MODAL = 'modalGeneral/CLOSE_EXIT_INTENT_MODAL';
export const TOGGLE_IS_BANNER_SHOWN = 'modalGeneral/TOGGLE_IS_BANNER_SHOWN';

export const SET_POPUP_CONFIG_DATA = 'modalGeneral/SET_POPUP_CONFIG_DATA';

export const modal25SecDelay = 20000;
export const REFRESH_TIMEOUT = 1000 * 60 * 9.5; // 9min 30sec

const initialState = {
  destination: null,
  searchFlightsResponse: null,
  // footer popup modal status
  callUsStatus: false,
  // one day modal status
  oneDayModalStatus: false,
  // same day modal status
  sameDayModalStatus: false,
  // call now modal status
  superDealModalStatus: false,
  // call later modal status
  getSuperDealCallLaterStatus: false,
  // default popup modal status
  callUs25SecStatus: false,
  triggered25Sec: false,
  // refresh modal status
  modal10MinuteStatus: false,
  // no seats modal status
  noSeatsModalStatus: false,
  // price change modal status
  priceChangedModalStatus: false,
  // exit intent modal status
  exitIntentModalStatus: false,
  // default popup shown in session handler
  callUs25SecShowed: false,
  phoneData: { phone: '' },
  verifyBookingData: {},
  isBannersShown: false,
  popupConfigData: {},
};

const modalGeneralReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_CALLUS_DATA:
      return {
        ...state,
        destination: action.data.airportsToResponse[0].airportCity.name,
        searchFlightsResponse: action.data.searchFlightsResponse,
      };
    case CHANGE_CALL_US_STATUS:
      return {
        ...state,
        callUsStatus: !state.callUsStatus,
      };
    case CLOSE_DEFAULT_MODALS:
      return {
        ...state,
        oneDayModalStatus: false,
        sameDayModalStatus: false,
        superDealModalStatus: false,
        getSuperDealCallLaterStatus: false,
        callUs25SecStatus: false, //defaultPopup
        callUsStatus: false, //footerPopup
      };
    case OPEN_SUPER_DEAL_MODAL: //callNow
      return {
        ...state,
        oneDayModalStatus: false,
        sameDayModalStatus: false,
        superDealModalStatus: true,
        getSuperDealCallLaterStatus: false,
      };
    case OPEN_SUPER_DEAL_CALL_LATER_MODAL: //callLater
      return {
        ...state,
        oneDayModalStatus: false,
        sameDayModalStatus: false,
        superDealModalStatus: false,
        getSuperDealCallLaterStatus: true,
      };
    case OPEN_ONE_DAY_MODAL:
      return {
        ...state,
        oneDayModalStatus: true,
        sameDayModalStatus: false,
        superDealModalStatus: false,
        getSuperDealCallLaterStatus: false,
      };
    case OPEN_SAME_DAY_MODAL:
      return {
        ...state,
        oneDayModalStatus: false,
        sameDayModalStatus: true,
        superDealModalStatus: false,
        getSuperDealCallLaterStatus: false,
      };
    case START_25_SEC_MODAL_COUNTDOWN:
      return {
        ...state,
        callUs25SecStatus: true,
      };
    case TRIGGER_25_SEC_MODAL_COUNTDOWN:
      return {
        ...state,
        triggered25Sec: !state.triggered25Sec,
      };
    case TOGGLE_25_SEC_MODAL:
      const {
        oneDayModalStatus,
        sameDayModalStatus,
        modal10MinuteStatus,
        noSeatsModalStatus,
        priceChangedModalStatus,
        superDealModalStatus,
        getSuperDealCallLaterStatus,
        callUsStatus,
      } = state;

      // check if any other modal/popup is currently open
      const isOtherModalOpen =
        oneDayModalStatus ||
        sameDayModalStatus ||
        modal10MinuteStatus ||
        noSeatsModalStatus ||
        priceChangedModalStatus ||
        superDealModalStatus ||
        getSuperDealCallLaterStatus ||
        callUsStatus;

      return {
        ...state,
        // dont show default popup modal if other popups already open
        callUs25SecStatus: isOtherModalOpen ? false : !state.callUs25SecStatus,
        callUs25SecShowed: true,
      };
    case REFRESH_30_MINUTE_MODAL_TIMEOUT:
      return {
        ...state,
        modal30MinuteTimeoutStatus: !state.callUs25SecStatus,
      };
    case TOGGLE_10_MINUTE_MODAL:
      return {
        ...state,
        modal10MinuteStatus:
          !state.noSeatsModalStatus && !state.priceChangedModalStatus ? !state.modal10MinuteStatus : false,
        ...(!state.noSeatsModalStatus &&
          !state.priceChangedModalStatus && {
            callUsStatus: false,
            oneDayModalStatus: false,
            sameDayModalStatus: false,
            superDealModalStatus: false,
            getSuperDealCallLaterStatus: false,
            callUs25SecStatus: false,
            noSeatsModalStatus: false,
            priceChangedModalStatus: false,
          }),
      };
    case TOGGLE_NO_SEATS_MODAL:
      return {
        ...state,
        noSeatsModalStatus: !state.noSeatsModalStatus,
        priceChangedModalStatus: false,
      };
    case TOGGLE_PRICE_CHANGED_MODAL:
      return {
        ...state,
        priceChangedModalStatus: !state.priceChangedModalStatus,
        noSeatsModalStatus: false,
      };
    case SAVE_PHONE_DATA:
      return {
        ...state,
        phoneData: action.data,
      };
    case SAVE_VERIFY_BOOKING_DATA:
      return {
        ...state,
        verifyBookingData: action.data,
      };
    case OPEN_EXIT_INTENT_MODAL:
      return {
        ...state,
        exitIntentModalStatus: true,
        // don't change status of other modals on exit intent close
      };
    case CLOSE_EXIT_INTENT_MODAL:
      return {
        ...state,
        // open exit intent irrespective of other modals
        exitIntentModalStatus: false,
      };

    case TOGGLE_IS_BANNER_SHOWN:
      return {
        ...state,
        isBannersShown: action.data,
      };
    case SET_POPUP_CONFIG_DATA:
      return {
        ...state,
        popupConfigData: action.data,
      };
    default:
      return state;
  }
};

export const openExitIntentModal = () => {
  return {
    type: OPEN_EXIT_INTENT_MODAL,
  };
};

export const closeExitIntentModal = () => {
  return {
    type: CLOSE_EXIT_INTENT_MODAL,
  };
};

export const saveCallUsData = (data) => {
  return {
    type: SAVE_CALLUS_DATA,
    data,
  };
};

export const toggleCallUsFooterModal = () => {
  return {
    type: CHANGE_CALL_US_STATUS,
  };
};

export const toggleIsBannerShown = (data) => {
  return {
    type: TOGGLE_IS_BANNER_SHOWN,
    data,
  };
};

export const toggle25SecModal = () => {
  return {
    type: TOGGLE_25_SEC_MODAL,
  };
};
export const toggle10MinuteModal = () => {
  return {
    type: TOGGLE_10_MINUTE_MODAL,
  };
};

export const closeDefaultModals = () => {
  return {
    type: CLOSE_DEFAULT_MODALS,
  };
};
export const openSuperDealModal = () => ({
  type: OPEN_SUPER_DEAL_MODAL,
});

export const openSuperDealCallLater = () => ({
  type: OPEN_SUPER_DEAL_CALL_LATER_MODAL,
});

export const openOneDayModal = () => ({
  type: OPEN_ONE_DAY_MODAL,
});
export const openSameDayModal = () => ({
  type: OPEN_SAME_DAY_MODAL,
});

export const start25SecModalCountdown = () => {
  return {
    type: START_25_SEC_MODAL_COUNTDOWN,
  };
};
export const trigger25SecModalCountdown = () => {
  return {
    type: TRIGGER_25_SEC_MODAL_COUNTDOWN,
  };
};
export const toggleNoSeatsModal = () => {
  return {
    type: TOGGLE_NO_SEATS_MODAL,
  };
};
export const togglePriceChangedModal = () => {
  return {
    type: TOGGLE_PRICE_CHANGED_MODAL,
  };
};
export const savePhoneData = () => {
  return {
    type: SAVE_PHONE_DATA,
  };
};
export const saveVerifyBookingData = (data) => {
  return {
    type: SAVE_VERIFY_BOOKING_DATA,
    data,
  };
};
export const setPopupConfigData = ({ callNow, callLater, footerPopup, defaultPopup }) => {
  return {
    type: SET_POPUP_CONFIG_DATA,
    data: { callNow, callLater, footerPopup, defaultPopup },
  };
};

//selectors
export const getCallUsData = (state) => {
  return {
    destination: state.modalGeneral.destination,
    searchFlightsResponse: state.modalGeneral.searchFlightsResponse,
  };
};

export const getCallUsFooterStatus = (state) => state.modalGeneral.callUsStatus;
export const getOneDayModalStatus = (state) => state.modalGeneral.oneDayModalStatus;
export const getSameDayModalStatus = (state) => state.modalGeneral.sameDayModalStatus;
export const getSuperDealModalStatus = (state) => state.modalGeneral.superDealModalStatus;
export const getSuperDealCallLaterStatus = (state) => state.modalGeneral.getSuperDealCallLaterStatus;
export const get25SecModalStatus = (state) => state.modalGeneral.callUs25SecStatus;
export const get25SecModalTriggeredStatus = (state) => state.modalGeneral.triggered25Sec;
export const get10MinuteModalStatus = (state) => state.modalGeneral.modal10MinuteStatus;
export const getPriceChangedModalStatus = (state) => state.modalGeneral.priceChangedModalStatus;
export const getNoSeatsModalStatus = (state) => state.modalGeneral.noSeatsModalStatus;
export const getPhoneData = (state) => state.modalGeneral.phoneData;
export const getVerifyBookingData = (state) => state.modalGeneral.verifyBookingData;
export const getExitIntentModalStatus = (state) => state.modalGeneral.exitIntentModalStatus;
export const get25SecShowedStatus = (state) => state.modalGeneral.callUs25SecShowed;
export const getIsBannersShown = (state) => state.modalGeneral.isBannersShown;
export const getPopupConfigData = (state) => state.modalGeneral.popupConfigData;

export default modalGeneralReducer;
