import moment from 'moment';
import * as Yup from 'yup';

import { ServiceClass, TripType } from '../../constants/global';

const destinationError = 'Enter the correct destination';
const datesError = 'Enter the correct travel dates';

export function mapPropsToValues({ queryFilters }) {
  const departureDate = queryFilters.startDate
    ? moment
        .utc(queryFilters.startDate * 1000)
        .startOf('day')
        .add('12', 'hours')
        .unix()
    : moment()
        .startOf('day')
        .add('12', 'hours')
        .unix();

  const arrivalDate = queryFilters.endDate
    ? moment
        .utc(queryFilters.endDate * 1000)
        .startOf('day')
        .add('12', 'hours')
        .unix()
    : moment
        .unix(departureDate)
        .add('7', 'days')
        .startOf('day')
        .add('12', 'hours')
        .unix();

  return {
    serviceClass: queryFilters.serviceClass || ServiceClass.ECONOMY.value,
    tripType: TripType.ROUND_TRIP,
    adults: queryFilters.adults || 1,
    children: queryFilters.children || 0,
    infants: queryFilters.infatns || 0,
    origin: queryFilters.from || null,
    destination: queryFilters.to || null,
    departureDate,
    arrivalDate,
    email: '',
  };
}

export function validationSchema({ maxPassengerCount = 9 }) {
  return Yup.object().shape({
    email: Yup.string()
      .email()
      .required(),
    customerNumber: Yup.string().required(),
    tripType: Yup.string().ensure(),
    departureDate: Yup.mixed()
      .nullable()
      .required(datesError),
    arrivalDate: Yup.mixed()
      .nullable()
      .when('tripType', (value, schema) => {
        return value === TripType.ROUND_TRIP ? schema.required(datesError) : schema;
      }),
    origin: Yup.mixed().required(destinationError),
    destination: Yup.mixed().required(destinationError),
    adults: Yup.number()
      .positive()
      .required('Please enter amount of adults')
      .test(
        'isLowerThanMaxPassengerCount',
        `Total number of passengers cannot be greater than ${maxPassengerCount}`,
        function isLowerThanMaxPassengerCount() {
          const { adults, infants, children } = this.parent;

          return adults + infants + children <= maxPassengerCount;
        },
      ),
    children: Yup.number().integer(),
    infants: Yup.number()
      .integer()
      .test(
        'isLowerOrEqualToAdults',
        'Number of infants exceeds maximum allowed per adult passenger',
        function isLowerThanMaxPassengerCount() {
          const { adults, infants } = this.parent;

          return infants <= adults;
        },
      ),
  });
}
