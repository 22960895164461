import './CallUs.css';

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Desktop, Mobile, PhoneOrangeIcon, Text, withTheme } from 'travelopod-components';

import FlexBox from '../../FlexBox';
import i18n from '../../../locales/i18next';
import { formatPhone } from '../../../helpers/phone';
import ResponseTapPhone from '../../ResponseTap/ResponseTapPhone';
import { getPhoneDataSelector } from '../../../store/modules/responseTap/phoneData';

/**
 * This component is on HEADER menu extreme right.
 * It shows phone number in desktop view.
 * It shows "CALL US 24/7" and phone icon on mobile view.
 */

const CallUs = ({ phoneData }) => {
  const isOzobi = window.location.href.includes('ozobi');

  return (
    <React.Fragment>
      <Desktop>
        <FlexBox alignItems="center" className="response-tap-header">
          <Text className="phone-text" secondary size="big">
            {i18n.t('components.header.callUs')}
          </Text>

          {!isOzobi ? (
            <Text
              className="phone"
              as={(props) => (
                <ResponseTapPhone
                  {...{ ...props, callUsEventId: 'call-us-phone-ibe-header-desktop' }}
                  renderFrom="phoneNumberHeader"
                />
              )}
              title={i18n.t('components.header.phone')}
              color="orange"
              size="huge"
              weight="bold"
            />
          ) : (
            <Text
              className="phone"
              title={i18n.t('components.header.phone')}
              color="orange"
              size="huge"
              weight="bold"
              decoration="underline"
            >
              1-669-232-7412
            </Text>
          )}
        </FlexBox>
      </Desktop>

      <Mobile>
        <FlexBox justifyContent="flex-end">
          <Text ma="r" color="orange" weight="bold" transform="uppercase" align="right">
            Call us <br />
            24/7
          </Text>
          {!isOzobi ? (
            <Text
              className="phone"
              as={(props) => (
                <ResponseTapPhone
                  {...{ ...props, callUsEventId: 'call-us-phone-ibe-header-mobile' }}
                  renderFrom="phoneNumberHeader"
                />
              )}
              title={i18n.t('components.header.phone')}
              color="orange"
              size="huge"
              weight="bold"
            />
          ) : (
            <Text
              className="phone"
              title={i18n.t('components.header.phone')}
              color="orange"
              size="huge"
              weight="bold"
              decoration="underline"
            >
              1-669-232-7412
            </Text>
          )}
          <a href={formatPhone(phoneData.phone, 'RFC3966')} id="call-us-phone-ibe-header-icon">
            <PhoneOrangeIcon className="tada2d" />
          </a>
        </FlexBox>
      </Mobile>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  phoneData: getPhoneDataSelector(state),
});

export default compose(connect(mapStateToProps), withTheme)(CallUs);
