import moment from 'moment';
import { get, isEmpty, omit } from 'lodash';

import { TRIP_TYPES } from '../constants/priceCalendar';
import { FUSED_DATE_FORMAT, TRIP_TYPE } from '../constants/global';

export const formPaxReference = (passengersCount) =>
  [
    {
      type: 'ADT',
      number: +passengersCount.adultsQuantity,
    },
    {
      type: 'CH',
      number: +passengersCount.childrenQuantity,
    },
    {
      type: 'INF',
      number: +passengersCount.infantsQuantity,
    },
  ].filter((el) => Boolean(el.number));

export const getCodeOfDirection = (direction) => direction.trim().split(' ').pop();

export const checkIfTripTypeIsRound = (tripType) => tripType === TRIP_TYPES[0].value;

export const getPriceMatrixRequestData = (searchDataParams) => {
  const searchPricesData = {
    departDate: moment(get(searchDataParams, 'startDate')).unix(),
    to: getCodeOfDirection(get(searchDataParams, 'toDirection')),
    from: getCodeOfDirection(get(searchDataParams, 'fromDirection')),
    paxReference: formPaxReference(searchDataParams.passengersAmount),
    cabinType: get(searchDataParams, 'cabinType'),
  };

  if (checkIfTripTypeIsRound(searchDataParams.tripType)) {
    searchPricesData.returnDate = moment(get(searchDataParams, 'endDate')).unix();
  }

  return searchPricesData;
};

export const serializeCalendarFilters = (filters) => {
  const serializedFilters = {
    from: filters.from,
    to: filters.to,
    cabinType: filters.serviceClass,
    ...(filters.startDate && { departDate: +filters.startDate }),
    paxReference: formPaxReference({
      adultsQuantity: filters.adults,
      childrenQuantity: filters.children,
      infantsQuantity: filters.infants,
    }),
  };

  if (filters.tripType === 'ROUND_TRIP' && filters.endDate) {
    serializedFilters.returnDate = +filters.endDate;
  }

  return serializedFilters;
};

export const serializeSearchReportsFilters = (filters) => {
  const {
    adults,
    children,
    infants,
    from,
    to,
    tripType,
    startDate: departDate,
    endDate: returnDate,
    serviceClass: cabin,
    domain,
    userEmail,
  } = filters;

  return {
    adults: Number(adults) || 1,
    children: Number(children) || 0,
    infants: Number(infants) || 0,
    from,
    to,
    tripType,
    departDate: moment.unix(departDate).utc().format(FUSED_DATE_FORMAT),
    ...(returnDate &&
      tripType !== TRIP_TYPE.oneWay && {
        returnDate: moment.unix(returnDate).utc().format(FUSED_DATE_FORMAT),
      }),
    cabin,
    domain,
    userEmail,
  };
};

const getFLightListFilters = (filters) => {
  let updatedFilters = {
    ...filters,
  };

  if (isEmpty(updatedFilters)) {
    return updatedFilters;
  }

  return updatedFilters.stops === 'anyStopsCount' ? omit(updatedFilters, ['stops']) : updatedFilters;
};

export const serializeSearchFlightFilters = ({ startDate, serviceClass, ...filters }) => {
  const searchFilters = {
    ...getFLightListFilters(filters),
    departDate: moment.unix(startDate).utc().format(FUSED_DATE_FORMAT),
    cabin: serviceClass,
    optionsSearch: 'EXACT',
    searchType: 'internal',
  };

  filters.children && (searchFilters.children = filters.children);
  filters.infants && (searchFilters.infants = filters.infants);

  if (filters.tripType === 'ROUND_TRIP') {
    searchFilters.returnDate = moment.unix(filters.endDate).utc().format(FUSED_DATE_FORMAT);
  }

  return searchFilters;
};
