export const getGoogleAuthUrl = (currentUrl) => {
  const rootUrl = 'https://accounts.google.com/o/oauth2/v2/auth';

  const options = {
    client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || '',
    redirect_uri: process.env.REACT_APP_GOOGLE_OAUTH_REDIRECT_URI || '',
    response_type: 'code',
    scope: ['https://www.googleapis.com/auth/userinfo.profile', 'https://www.googleapis.com/auth/userinfo.email'].join(
      ' ',
    ),
    state: currentUrl,
  };

  const qs = new URLSearchParams(options);

  return `${rootUrl}?${qs.toString()}`;
};
